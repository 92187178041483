import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "requested-resources r-scroll" }
const _hoisted_2 = { class: "r-accordion" }
const _hoisted_3 = { class: "resource-list" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["data-resource-type", "onClick"]
const _hoisted_6 = { class: "resource-heading" }
const _hoisted_7 = {
  key: 0,
  class: "fal fa-minus info-click"
}
const _hoisted_8 = {
  key: 1,
  class: "fal fa-plus info-click"
}
const _hoisted_9 = {
  class: "info-expand",
  style: {"display":"none"}
}
const _hoisted_10 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.documents, (doc) => {
          return (_openBlock(), _createElementBlock("li", {
            key: doc.id,
            id: 'doc'+ doc.id,
            class: _normalizeClass(["resource-row align-items-start", {active: $setup.showInfo['doc'+ doc.id]}])
          }, [
            _createElementVNode("div", {
              class: "tease-doc-links document",
              "data-resource-type": doc.docType,
              onClick: $event => $setup.toggleInfo('doc'+ doc.id)
            }, [
              _createVNode($setup["imageWithFallback"], {
                class: "doc-thumb mr-4",
                alt: doc.thumbnailAltText,
                src: doc.thumbnail,
                "fallback-src": $setup.genericThumbUrl
              }, null, 8 /* PROPS */, ["alt", "src", "fallback-src"]),
              _createElementVNode("p", _hoisted_6, _toDisplayString(doc.title), 1 /* TEXT */),
              ($setup.showInfo['doc'+ doc.id])
                ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                : (_openBlock(), _createElementBlock("i", _hoisted_8))
            ], 8 /* PROPS */, _hoisted_5),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", {
                innerHTML: doc.description
              }, null, 8 /* PROPS */, _hoisted_10)
            ])
          ], 10 /* CLASS, PROPS */, _hoisted_4))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}