<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>
<!-- If there is a single document in the modal -->
<script lang="ts" setup>
  import type { DocumentResource } from '../../../../widgetDataTs.js';
  import imageWithFallback from '../../../../shared-components/image-with-fallback.vue';

  defineProps<{ 
    document: DocumentResource
  }>();

  var genericThumbUrl = "https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png";
</script>

<template>
  <div class="thumbnail-wrapper">
    <imageWithFallback class="main-thumbnail"
      :alt="document.thumbnailAltText"
      :src="document.thumbnail"
      :fallback-src="genericThumbUrl"
    />
  </div>
  <div class="d-flex flex-column download-now">
    <p class="content-label">
      {{ document.docType }}
    </p>
    <p class="doc-title">
      {{ document.title }}
    </p>
    <div class="before-complete">
      <p v-html="document.description"></p>
    </div>
  </div>
</template>